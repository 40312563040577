import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout" 
import { FaTwitter, FaLinkedin, FaFacebook } from "react-icons/fa"


const HomePage = ({ data }) => {

  return (
    <>
      <Layout background="true">          

          <div className="text-wrapper">
            <h2>ARTISTE NUMERIQUE EN MOUVEMENT.</h2>
            <p>
              Après avoir étudié à 42, HEC puis apprentis pour Pietragalla & Derouault, il travaille à créer des ponts entre danse et numérique en tant que directeur artistique de la <a href="https://ciebeta.fr">compagnie Bêta</a>.
            </p>
          
            <a className="button" href="/work">Découvrir ses projets</a>
          </div>

      </Layout>
    </>
  )
}

export default HomePage

//export const query = graphql`
//  {
//    BlogPostQuery: allMarkdownRemark(
//      sort: { fields: [frontmatter___date], order: DESC }
//      limit: 3
//    ) {
//      totalCount
//      edges {
//        node {
//          frontmatter {
//            title
//            date
//            path
//            featuredImage {
//              childImageSharp {
//                gatsbyImageData(
//                  layout: FULL_WIDTH
//                  placeholder: TRACED_SVG
//                  formats: [AUTO, WEBP]
//                )
//              }
//            }
//            featuredImageAlt
//          }
//          excerpt
//        }
//      }
//    }
//  }
//`
